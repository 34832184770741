import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter, useLocation } from 'react-router-dom';

import DashboardWhite from 'assets/Dashboard - White.svg';
import DashboardBlack from 'assets/Dashboard - Black.svg';
import InsightWhite from 'assets/insight White.png';
import InsightBlack from 'assets/insightBlack.png';
import AttackWhite from 'assets/Attack Surface - White.svg';
import AttackBlack from 'assets/Attack Surface - Black.svg';
import SecurityWhite from 'assets/Security Risks - White.svg';
import SecurityBlack from 'assets/Security Risks - Black.svg';
import DataleakWhite from 'assets/Data Leaks - White.svg';
import DataleakBlack from 'assets/Data Leaks - Black.svg';
import SupplyChainWhite from 'assets/supply-chainWhite.png';
import SupplyChainBlack from 'assets/supply-chainBlack.png';
import SettingsWhite from 'assets/Settings - White.svg';
import SettingsBlack from 'assets/Settings - Black.svg';
import IssueWhite from 'assets/Issue Tracker - White.svg';
import IssueBlack from 'assets/Issue Tracker - Black.svg';
import report from 'assets/file.png';
import reportWhite from 'assets/file_white.png';
import collapseDark from 'assets/collapse_dark.svg';
import expandDark from 'assets/expand_dark.svg';
import { CustomTooltip } from 'components/tooltip';
import { collapseSidebar, expandSidebar } from 'actions/modals';
import { fetchReportTab } from 'actions/auth';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
  SidebarContent
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

// import dashboard from 'reducers/dashboard';

const useStyles = makeStyles(() => ({
  menuItems: {
    color: 'var(--color-white)'
  },
  subMenuItems: {
    height: 45,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    // borderTopRightRadius: 4,
    // borderTopLeftRadius: 4
  },
  '&:hover': {
    background: '#F7F7F7 !important'
  },
  collapsedHead: {
    background: 'var(--color-blueBG)',
    color: 'var(--color-white) !important',
    borderBottom: '1px solid var(--color-stroke)',
    pointerEvents: 'none',
    height: 45,
    display: 'flex',
    alignItems: 'center'
  }
}));

const SideBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  // const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const sidebarCollapsed = useSelector(
    (state) => state.modals.sidebarCollapsed
  );
  const [selectedMenu, setSelectedMenu] = useState({
    dashboard: false,
    attack_surface: false,
    data_leaks: false,
    security_risks: false,
    issue_tracker: false,
    report: false,
    insights: false,
    supply_chain: false,
    settings: false
  });

  let darkTheme = JSON.parse(localStorage.getItem('darkTheme'));
  const warningVisible = useSelector((state) => state.modals.warningVisible);
  const reportToLevel = useSelector((state) => state.dashboard.REPORTS_TOP_LEVEL);
  const windowSize = useSelector((state) => state.authentication.windowSize);
  let role = localStorage.getItem('role')
    ? localStorage.getItem('role')
    : '';
  useEffect(() => {
    if (windowSize < 1540) {
      setTimeout(() => {
        dispatch(collapseSidebar());
      }, 100);
    }
  }, [windowSize])
  useEffect(() => {
    if (sidebarCollapsed) {
      setTimeout(() => {
        let submenus = document.getElementsByClassName('popper-inner');
        submenus[0].parentElement.style.marginTop = '70px';

        submenus[1].parentElement.style.marginTop = '42px';

        submenus[2].parentElement.style.marginTop = '42px';
        submenus[3].parentElement.style.marginTop = '70px';
        if (role === 'MANAGER' || role === 'ADMIN') {
          submenus[4].parentElement.style.marginTop =
            role === 'MANAGER' ? '135px' : '0px';
        }
        for (let i = 0; i < submenus.length; i++) {
          submenus[i].parentElement.style.width = '226px';
          submenus[i].parentElement.style.filter =
            'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.24))';
          submenus[i].children[0].style.background = 'white';
          submenus[i].style.borderRadius = '4px';
        }
      }, [500]);
    }
    // eslint-disable-next-line
  }, [sidebarCollapsed]);
  useEffect(() => {
    if(role === 'MANAGER' || role === 'ADMIN' || role === "USER" || role === "SUBSIDIARY" || role === 'VENDOR') {
      dispatch(fetchReportTab())
    }
  }, [])

  useEffect(() => {
    if (sidebarCollapsed && !warningVisible) {
      let submenus = document.getElementsByClassName('popper-inner');
      submenus[0].parentElement.style.marginTop = '42px';
      submenus[1].parentElement.style.marginTop = '0px';
      submenus[2].parentElement.style.marginTop = '20px';
      submenus[3].parentElement.style.marginTop =
        role === 'MANAGER' ? '88px' : '110px';
    } else if (sidebarCollapsed && warningVisible) {
      let submenus = document.getElementsByClassName('popper-inner');
      submenus[0].parentElement.style.marginTop = '90px';
      submenus[1].parentElement.style.marginTop = '45px';
      submenus[2].parentElement.style.marginTop = '68px';
      submenus[3].parentElement.style.marginTop =
        role === 'MANAGER' ? '135px' : '158px';
    }
    // eslint-disable-next-line
  }, [warningVisible]);

  const handleCollapse = (event, type) => {
    if (type === 'expand') {
      // setSidebarCollapsed(false);
      dispatch(expandSidebar());
    } else {
      // setSidebarCollapsed(true);
      dispatch(collapseSidebar());
    }
    event.preventDefault();
  };

  const handleSubmenuClick = (e, type) => {
    if (type === 'attack') {
      setSelectedMenu({
        dashboard: false,
        attack_surface: true,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: false,
        report: false,
        supply_chain: false,
        settings: false
      });
    } else if (type === 'dataleak') {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: true,
        security_risks: false,
        insights: false,
        supply_chain: false,
        issue_tracker: false,
        report: false,
        settings: false
      });
    } else if (type === 'securityRisk') {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: true,
        insights: false,
        issue_tracker: false,
        report: false,
        supply_chain: false,
        settings: false
      });
    } else if (type === 'issueTracker') {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: true,
        report: true,
        supply_chain: false,
        settings: false
      });
    } else if (type === 'insights') {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        issue_tracker: false,
        report: false,
        supply_chain: false,
        insights: true,
        settings: false
      });
    } else if (type === 'settings') {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: false,
        report: false,
        supply_chain: false,
        settings: true
      });
    } else if (type === 'supply_chain') {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: false,
        report: false,
        supply_chain: true,
        settings: false
      });
    } else if (type === 'report') {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: false,
        report: true,
        supply_chain: false,
        settings: false
      });
    }
  };

  useEffect(() => {
    sidebarCollapsed ? dispatch(collapseSidebar()) : dispatch(expandSidebar());
    if (location.pathname === '/') {
      setSelectedMenu({
        dashboard: true,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: false,
        report: false,
        supply_chain: false,
        settings: false
      });
    } else if (location.pathname.includes('/attack-surface/technologies') || location.pathname.includes('/attack-surface/possible-asset-exposure') || location.pathname.includes('/attack-surface/technology')) {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: false,
        report: false,
        supply_chain: true,
        settings: false
      });
    } else if (location.pathname.includes('/attack-surface')) {
      setSelectedMenu({
        dashboard: false,
        attack_surface: true,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: false,
        report: false,
        supply_chain: false,
        settings: false
      });
    } else if (location.pathname.includes('/code-exposures')) {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: true,
        security_risks: false,
        insights: false,
        issue_tracker: false,
        report: false,
        supply_chain: false,
        settings: false
      });
    } else if (location.pathname.includes('/security-risks')) {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: true,
        insights: false,
        issue_tracker: false,
        report: false,
        supply_chain: false,
        settings: false
      });
    } else if (location.pathname.includes('/issue-tracker')) {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: true,
        report: false,
        supply_chain: false,
        settings: false
      });
    } else if (location.pathname.includes('/insights')) {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        insights: true,
        issue_tracker: false,
        report: false,
        supply_chain: false,
        settings: false
      });
    } else if (location.pathname.includes('/settings')) {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: false,
        report: false,
        supply_chain: false,
        settings: true
      });
    } else if (location.pathname.includes('/historical-downloads')) {
      setSelectedMenu({
        dashboard: false,
        attack_surface: false,
        data_leaks: false,
        security_risks: false,
        insights: false,
        issue_tracker: false,
        report: true,
        supply_chain: false,
        settings: false
      });
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      style={{
        display: 'flex',
        left: 0,
        height: warningVisible ? '95.5vh' : '99vh'
      }}>
      <ProSidebar
        collapsed={sidebarCollapsed}
        style={{
          position: 'sticky',
        }}>
        <SidebarContent style={{
          overflowY: 'auto',
          overflowX: 'hidden'
        }}>
          {sidebarCollapsed ? (
            <Menu popperArrow>
              <CustomTooltip title="Dashboard">
                <MenuItem
                  active={selectedMenu.dashboard}
                  className={classes.menuItems}
                  style={{
                    pointerEvents: selectedMenu.dashboard ? 'none' : 'all',
                    marginTop: 24,
                    background: selectedMenu.dashboard ? 'white' : 'var(--color-blueBG)'
                  }}
                  icon={
                    selectedMenu.dashboard ? (
                      <img src={DashboardBlack} alt="dashboard icon" />
                    ) : (
                      <img src={DashboardWhite} alt="dashboard icon" />
                    )
                  }>
                  Dashboard
                  <Link to="/" />
                </MenuItem>
              </CustomTooltip>
              <SubMenu
                title={
                  <p
                    style={{
                      color: 'var(--color-white)'
                    }}>
                    Attack Surface
                  </p>
                }
                id="attack"
                className="Collapsed"
                onClick={(e) => handleSubmenuClick(e, 'attack')}
                style={{
                  background: selectedMenu.attack_surface ? 'white' : 'var(--color-blueBG)'
                }}
                open={selectedMenu.attack_surface}
                icon={
                  selectedMenu.attack_surface ? (
                    <img src={AttackBlack} alt="attack surface icon" />
                  ) : (
                    <img src={AttackWhite} alt="attack surface icon" />
                  )
                }>
                <MenuItem className={classes.collapsedHead}>
                  Attack Surface
                </MenuItem>
                <MenuItem
                  active={location.pathname === '/attack-surface'}
                  style={{
                    color:
                      location.pathname === '/attack-surface'
                        ? 'var(--color-white)'
                        : 'var(--color-text)',
                    background:
                      location.pathname === '/attack-surface'
                        ? 'var(--color-blueBG)'
                        : 'none',
                    borderLeft:
                      location.pathname === '/attack-surface'
                        ? '4px solid var(--color-primary)'
                        : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents:
                      location.pathname === '/attack-surface' ? 'none' : 'all'
                  }}
                  className={classes.subMenuItems}>
                  Asset Exposure
                  <Link to="/attack-surface?is_obsolete=false" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/asset-inventory'
                    )
                      ? 'var(--color-white)'
                      : 'var(--color-text)',
                    background: location.pathname.includes(
                      '/attack-surface/asset-inventory'
                    )
                      ? 'var(--color-blueBG)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/asset-inventory'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/asset-inventory'
                    )
                      ? 'none'
                      : 'all'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/asset-inventory'
                  )}>
                  Asset Inventory
                  <Link to="/attack-surface/asset-inventory" />
                </MenuItem>
                <MenuItem
                  className="Preferences"
                  style={{
                    color:
                      location.pathname === '/attack-surface/web-applications'
                        ? 'var(--color-white)'
                        : 'var(--color-text)',

                    background:
                      location.pathname === '/attack-surface/web-applications'
                        ? 'var(--color-blueBG)'
                        : 'none',
                    borderLeft:
                      location.pathname === '/attack-surface/web-applications'
                        ? '4px solid var(--color-primary)'
                        : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents:
                      location.pathname === '/attack-surface/web-applications'
                        ? 'none'
                        : 'all',
                    height: 45,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  active={location.pathname === '/attack-surface/web-applications'}>
                  Web Applications
                  <Link to="/attack-surface/web-applications" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/asset-screenshots'
                    )
                      ? 'var(--color-white)'
                      : 'var(--color-text)',

                    background: location.pathname.includes(
                      '/attack-surface/asset-screenshots'
                    )
                      ? 'var(--color-blueBG)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/asset-screenshots'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/asset-screenshots'
                    )
                      ? 'none'
                      : 'all'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/asset-screenshots'
                  )}>
                  Screenshots
                  <Link to="/attack-surface/asset-screenshots" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/customer-mindmap'
                    )
                      ? 'var(--color-white)'
                      : 'var(--color-text)',

                    background: location.pathname.includes(
                      '/attack-surface/customer-mindmap'
                    )
                      ? 'var(--color-blueBG)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/customer-mindmap'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/customer-mindmap'
                    )
                      ? 'none'
                      : 'all'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/customer-mindmap'
                  )}>
                  Attack Surface Graph
                  <Link to="/attack-surface/customer-mindmap" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                title={
                  <p
                    style={{
                      color: 'var(--color-white)'
                    }}>
                    External Supply Chain
                  </p>
                }
                className="Collapsed"
                onClick={(e) => handleSubmenuClick(e, 'supply_chain')}
                style={{
                  background: selectedMenu.supply_chain ? 'white' : 'var(--color-blueBG)'
                }}
                open={selectedMenu.supply_chain}
                icon={
                  selectedMenu.supply_chain ? (
                    <img src={SupplyChainBlack} alt="Supply Chain icon" width={20} />
                  ) : (
                    <img src={SupplyChainWhite} alt="Supply Chain icon" width={20} />
                  )
                }>
                <MenuItem className={classes.collapsedHead}>
                  External Supply Chain
                </MenuItem>

                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? 'var(--color-white)'
                      : 'var(--color-text)',

                    background: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? 'var(--color-blueBG)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? 'none'
                      : 'all'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/possible-asset-exposure'
                  )}>
                  Third Party Assets
                  <Link to="/attack-surface/possible-asset-exposure" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? 'var(--color-white)'
                      : 'var(--color-text)',
                    background: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? 'var(--color-blueBG)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? 'none'
                      : 'all'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/technologies'
                  )}>
                  Technologies With CVEs
                  <Link to="/attack-surface/technologies" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/technology'
                    )
                      ? 'var(--color-white)'
                      : 'var(--color-text)',
                    background: location.pathname.includes(
                      '/attack-surface/technology'
                    )
                      ? 'var(--color-blueBG)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/technology'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/technology'
                    )
                      ? 'none'
                      : 'all'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/technology'
                  )}>
                  All Technologies
                  <Link to="/attack-surface/technology" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                title={
                  <p
                    style={{
                      color: 'var(--color-white)'
                    }}>
                    Data Leaks
                  </p>
                }
                onClick={(e) => handleSubmenuClick(e, 'dataleak')}
                style={{
                  background: selectedMenu.data_leaks ? 'white' : 'var(--color-blueBG)'
                }}
                open={selectedMenu.data_leaks}
                className="Collapsed"
                icon={
                  selectedMenu.data_leaks ? (
                    <img src={DataleakBlack} alt="attack surface icon" />
                  ) : (
                    <img src={DataleakWhite} alt="attack surface icon" />
                  )
                }>
                <MenuItem className={classes.collapsedHead}>
                  Data Leaks
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color:
                      location.pathname === '/code-exposures'
                        ? 'var(--color-white)'
                        : 'var(--color-text)',

                    background:
                      location.pathname === '/code-exposures'
                        ? 'var(--color-blueBG)'
                        : 'none',
                    borderLeft:
                      location.pathname === '/code-exposures'
                        ? '4px solid var(--color-primary)'
                        : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents:
                      location.pathname === '/code-exposures' ? 'none' : 'all'
                  }}
                  active={location.pathname === '/code-exposures'}>
                  All Data Leaks
                  <Link to="/code-exposures?is_obsolete=false" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/code-exposures/table-layout'
                    )
                      ? 'var(--color-white)'
                      : 'var(--color-text)',

                    background: location.pathname.includes(
                      '/code-exposures/table-layout'
                    )
                      ? 'var(--color-blueBG)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/code-exposures/table-layout'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents: location.pathname.includes(
                      '/code-exposures/table-layout'
                    )
                      ? 'none'
                      : 'all'
                  }}
                  active={location.pathname.includes(
                    '/code-exposures/table-layout'
                  )}>
                  Tabular View
                  <Link to="/code-exposures/table-layout" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                title={
                  <p
                    style={{
                      color: 'var(--color-white)'
                    }}>
                    Security Risks
                  </p>
                }
                onClick={(e) => handleSubmenuClick(e, 'securityRisk')}
                className="Collapsed"
                open={selectedMenu.security_risks}
                style={{
                  background: selectedMenu.security_risks ? 'white' : 'var(--color-blueBG)'
                }}
                icon={
                  selectedMenu.security_risks ? (
                    <img src={SecurityBlack} alt="attack surface icon" />
                  ) : (
                    <img src={SecurityWhite} alt="attack surface icon" />
                  )
                }>
                {sidebarCollapsed ? (
                  <MenuItem className={classes.collapsedHead}>
                    Security Risks
                  </MenuItem>
                ) : null}
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color:
                      location.pathname === '/security-risks'
                        ? 'var(--color-white)'
                        : 'var(--color-text)',
                    background:
                      location.pathname === '/security-risks'
                        ? 'var(--color-blueBG)'
                        : 'none',
                    borderLeft:
                      location.pathname === '/security-risks'
                        ? '4px solid var(--color-primary)'
                        : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents:
                      location.pathname === '/security-risks' ? 'none' : 'all'
                  }}
                  active={location.pathname === '/security-risks'}>
                  All Security Risks
                  <Link to="/security-risks?is_obsolete=false" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/security-risks/table-layout'
                    )
                      ? 'var(--color-white)'
                      : 'var(--color-text)',

                    background: location.pathname.includes(
                      '/security-risks/table-layout'
                    )
                      ? 'var(--color-blueBG)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/security-risks/table-layout'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents: location.pathname.includes(
                      '/security-risks/table-layout'
                    )
                      ? 'none'
                      : 'all'
                  }}
                  active={location.pathname.includes(
                    '/security-risks/table-layout'
                  )}>
                  Tabular View
                  <Link to="/security-risks/table-layout" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/security-risks/vulnerabilities'
                    )
                      ? 'var(--color-white)'
                      : 'var(--color-text)',

                    background: location.pathname.includes(
                      '/security-risks/vulnerabilities'
                    )
                      ? 'var(--color-blueBG)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/security-risks/vulnerabilities'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-white)',
                    width: '222px',
                    pointerEvents: location.pathname.includes(
                      '/security-risks/vulnerabilities'
                    )
                      ? 'none'
                      : 'all'
                  }}
                  active={location.pathname.includes(
                    '/security-risks/vulnerabilities'
                  )}>
                  Detailed View
                  <Link to="/security-risks/vulnerabilities" />
                </MenuItem>
              </SubMenu>
              <CustomTooltip title="Insights">
                <MenuItem
                  active={selectedMenu.insights}
                  className={classes.menuItems}
                  style={{
                    pointerEvents: selectedMenu.insights ? 'none' : 'all',
                    background: selectedMenu.insights ? 'white' : 'none'
                  }}
                  icon={
                    selectedMenu.insights ? (
                      <img src={InsightBlack} alt="Insight icon" width={20} />
                    ) : (
                      <img src={InsightWhite} alt="Insight icon" width={20} />
                    )
                  }>
                  Insights
                  <Link to="/insights" />
                </MenuItem>
              </CustomTooltip>
              <CustomTooltip title="Issue Tracker">
                <MenuItem
                  active={selectedMenu.issue_tracker}
                  className={classes.menuItems}
                  style={{
                    pointerEvents: selectedMenu.issue_tracker ? 'none' : 'all',
                    background: selectedMenu.issue_tracker ? 'white' : 'none'
                  }}
                  icon={
                    selectedMenu.issue_tracker ? (
                      <img src={IssueBlack} alt="Insight icon" />
                    ) : (
                      <img src={IssueWhite} alt="Insight icon" />
                    )
                  }>
                  Issue Tracker
                  <Link to="/issue-tracker/kanban" />
                </MenuItem>
              </CustomTooltip>
              {reportToLevel === 'ENABLED' ?
                <CustomTooltip title="Reports">
                  <MenuItem
                    active={selectedMenu.report}
                    className={classes.menuItems}
                    style={{
                      pointerEvents: reportToLevel === 'ENABLED' ? selectedMenu.report ? 'none' : 'all' : 'none',
                      background: selectedMenu.report ? 'white' : 'none'
                    }}
                    icon={
                      selectedMenu.report ? (
                        <img src={report} alt="Report icon" width={20} />
                      ) : (
                        <img src={reportWhite} alt="Report icon" width={20} style={{ filter: reportToLevel === 'DISABLED' ? 'brightness(0.5)' : 'unset' }} />
                      )
                    }>
                    Reports
                    <Link to="/historical-downloads" />
                  </MenuItem>
                </CustomTooltip>
                :
                <CustomTooltip title="You do not have required permission to access this">
                  <MenuItem
                    active={selectedMenu.report}
                    className={classes.menuItems}
                    style={{
                      pointerEvents: selectedMenu.report ? 'none' : 'all',
                      background: selectedMenu.report ? 'white' : 'none'
                    }}
                    icon={
                      selectedMenu.report ? (
                        <img src={report} alt="Report icon" width={20} />
                      ) : (
                        <img src={reportWhite} alt="Report icon" width={20} style={{ filter: reportToLevel === 'DISABLED' ? 'brightness(0.5)' : 'unset' }} />
                      )
                    }>
                    Reports
                  </MenuItem>
                </CustomTooltip>
              }
              {role === 'MANAGER' || role === 'ADMIN' || role === "PENDING" || role === "ORG_PENDING" ?
                <SubMenu
                  title={
                    <p
                      style={{
                        color: 'var(--color-white)'
                      }}>
                      Settings
                    </p>
                  }
                  open={selectedMenu.settings}
                  className="Collapsed"
                  onClick={(e) => handleSubmenuClick(e, 'settings')}
                  style={{
                    background: selectedMenu.settings ? 'white' : 'var(--color-blueBG)'
                  }}
                  icon={
                    selectedMenu.settings ? (
                      <img src={SettingsBlack} alt="attack surface icon" />
                    ) : (
                      <img src={SettingsWhite} alt="attack surface icon" />
                    )
                  }>
                  {sidebarCollapsed ? (
                    <MenuItem className={classes.collapsedHead}>
                      Settings
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings'
                          ? 'var(--color-white)'
                          : 'var(--color-text)',

                      background:
                        location.pathname === '/settings' ? 'var(--color-blueBG)' : 'none',
                      borderLeft:
                        location.pathname === '/settings'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-white)',
                      width: '222px',
                      pointerEvents:
                        location.pathname === '/settings' ? 'none' : 'all'
                    }}
                    active={location.pathname === '/settings'}>
                    Seed Information
                    <Link to="/settings" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/whitelisted-ips'
                          ? 'var(--color-white)'
                          : 'var(--color-text)',

                      background:
                        location.pathname === '/settings/whitelisted-ips' ? 'var(--color-blueBG)' : 'none',
                      borderLeft:
                        location.pathname === '/settings/whitelisted-ips'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-white)',
                      width: '222px',
                      pointerEvents:
                        location.pathname === '/settings/whitelisted-ips' ? 'none' : 'all'
                    }}
                    active={location.pathname === '/settings/whitelisted-ips'}>
                    Scanner IP Addresses
                    <Link to="/settings/whitelisted-ips" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/scan-summary'
                          ? 'var(--color-white)'
                          : 'var(--color-text)',

                      background:
                        location.pathname === '/settings/scan-summary' ? 'var(--color-blueBG)' : 'none',
                      borderLeft:
                        location.pathname === '/settings/scan-summary'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-white)',
                      width: '222px',
                      pointerEvents:
                        location.pathname === '/settings/scan-summary' ? 'none' : 'all'
                    }}
                    active={location.pathname === '/settings/scan-summary'}>
                    Scan Summary
                    <Link to="/settings/scan-summary" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/muted-instances'
                          ? 'var(--color-white)'
                          : 'var(--color-text)',

                      background:
                        location.pathname === '/settings/muted-instances'
                          ? 'var(--color-blueBG)'
                          : 'none',
                      borderLeft:
                        location.pathname === '/settings/muted-instances'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-white)',
                      width: '222px',
                      pointerEvents:
                        location.pathname === '/settings/muted-instances'
                          ? 'none'
                          : 'all'
                    }}
                    active={location.pathname === '/settings/muted-instances'}>
                    Muted Instances
                    <Link to="/settings/muted-instances" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/integrations'
                          ? 'var(--color-white)'
                          : 'var(--color-text)',

                      background:
                        location.pathname === '/settings/integrations'
                          ? 'var(--color-blueBG)'
                          : 'none',
                      borderLeft:
                        location.pathname === '/settings/integrations'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-white)',
                      width: '222px',
                      pointerEvents:
                        location.pathname === '/settings/integrations'
                          ? 'none'
                          : 'all'
                    }}
                    active={location.pathname === '/settings/integrations'}>
                    Integrations and API
                    <Link to="/settings/integrations" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/user-profile'
                          ? 'var(--color-white)'
                          : 'var(--color-text)',
                      background:
                        location.pathname === '/settings/user-profile'
                          ? 'var(--color-blueBG)'
                          : 'none',
                      borderLeft:
                        location.pathname === '/settings/user-profile'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-white)',
                      width: '222px',
                      pointerEvents:
                        location.pathname === '/settings/user-profile'
                          ? 'none'
                          : 'all'
                    }}
                    active={location.pathname === '/settings/user-profile'}>
                    Profile Page
                    <Link to="/settings/user-profile" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/org-team'
                          ? 'var(--color-white)'
                          : 'var(--color-text)',

                      background:
                        location.pathname === '/settings/org-team'
                          ? 'var(--color-blueBG)'
                          : 'none',
                      borderLeft:
                        location.pathname === '/settings/org-team'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-white)',
                      width: '222px',
                      pointerEvents:
                        location.pathname === '/settings/org-team'
                          ? 'none'
                          : 'all'
                    }}
                    active={location.pathname === '/settings/org-team'}>
                    Team & Org
                    <Link to="/settings/org-team" />
                  </MenuItem>
                  <MenuItem
                    className="Preferences"
                    style={{
                      color:
                        location.pathname === '/settings/asset-grouping'
                          ? 'var(--color-white)'
                          : 'var(--color-text)',

                      background:
                        location.pathname === '/settings/asset-grouping'
                          ? 'var(--color-blueBG)'
                          : 'none',
                      borderLeft:
                        location.pathname === '/settings/asset-grouping'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-white)',
                      width: '222px',
                      pointerEvents:
                        location.pathname === '/settings/asset-grouping'
                          ? 'none'
                          : 'all',
                      height: 45,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    active={location.pathname === '/settings/asset-grouping'}>
                    Asset Grouping
                    <Link to="/settings/asset-grouping" />
                  </MenuItem>

                  <MenuItem
                    className="Preferences"
                    style={{
                      color:
                        location.pathname === '/settings/preferences'
                          ? 'var(--color-white)'
                          : 'var(--color-text)',

                      background:
                        location.pathname === '/settings/preferences'
                          ? 'var(--color-blueBG)'
                          : 'none',
                      borderLeft:
                        location.pathname === '/settings/preferences'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-white)',
                      width: '222px',
                      pointerEvents:
                        location.pathname === '/settings/preferences'
                          ? 'none'
                          : 'all',
                      height: 45,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    active={location.pathname === '/settings/preferences'}>
                    Notifications Preferences
                    <Link to="/settings/preferences" />
                  </MenuItem>
                  {role === 'MANAGER' ? null : (
                    <MenuItem
                      className={classes.subMenuItems}
                      style={{
                        color:
                          location.pathname === '/settings/billing'
                            ? 'var(--color-white)'
                            : 'var(--color-text)',

                        background:
                          location.pathname === '/settings/billing'
                            ? 'var(--color-blueBG)'
                            : 'none',
                        borderLeft:
                          location.pathname === '/settings/billing'
                            ? '4px solid var(--color-primary)'
                            : '4px solid var(--color-white)',
                        width: '222px',
                        pointerEvents:
                          location.pathname === '/settings/billing'
                            ? 'none'
                            : 'all'
                      }}
                      active={location.pathname === '/settings/billing'}>
                      Billing and Plans
                      <Link to="/settings/billing" />
                    </MenuItem>
                  )}
                </SubMenu>
                : null}
            </Menu>
          ) : (
            <Menu popperArrow>
              <MenuItem
                active={selectedMenu.dashboard}
                style={{
                  pointerEvents: selectedMenu.dashboard ? 'none' : 'all',
                  marginTop: 24,
                  color: selectedMenu.dashboard
                    ? 'black'
                    : 'var(--color-white)',
                  background: selectedMenu.dashboard
                    ? 'var(--color-white)'
                    : 'var(--color-blueBG)',
                  borderLeft: selectedMenu.dashboard
                    ? '2px solid var(--color-primary)'
                    : 'none'
                }}
                icon={
                  selectedMenu.dashboard ? (
                    <img src={DashboardBlack} alt="dashboard icon" />
                  ) : (
                    <img src={DashboardWhite} alt="dashboard icon" />
                  )
                }>
                Dashboard
                <Link to="/" />
              </MenuItem>
              <SubMenu
                title={
                  <p
                    style={{
                      color: 'var(--color-white)',
                      fontSize: '14px'
                    }}>
                    Attack Surface
                  </p>
                }
                id="attack"
                onClick={(e) => handleSubmenuClick(e, 'attack')}
                open={selectedMenu.attack_surface}
                className="Expanded"
                icon={<img src={AttackWhite} alt="attack surface icon" />}>
                <MenuItem
                  active={location.pathname === '/attack-surface'}
                  style={{
                    color:
                      location.pathname === '/attack-surface'
                        ? 'var(--color-text)'
                        : 'var(--color-white)',
                    background:
                      location.pathname === '/attack-surface'
                        ? 'var(--color-white)'
                        : 'none',
                    pointerEvents:
                      location.pathname === '/attack-surface' ? 'none' : 'all',
                    borderLeft:
                      location.pathname === '/attack-surface'
                        ? '4px solid var(--color-primary)'
                        : '4px solid var(--color-blueBG)'
                  }}
                  className={classes.subMenuItems}>
                  Asset Exposure
                  <Link to="/attack-surface?is_obsolete=false" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/asset-inventory'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/attack-surface/asset-inventory'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/asset-inventory'
                    )
                      ? 'none'
                      : 'all',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/asset-inventory'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/asset-inventory'
                  )}>
                  Asset Inventory
                  <Link to="/attack-surface/asset-inventory" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color:
                      location.pathname === '/attack-surface/web-applications'
                        ? 'var(--color-text)'
                        : 'var(--color-white)',
                    background:
                      location.pathname === '/attack-surface/web-applications'
                        ? 'var(--color-white)'
                        : 'none',
                    pointerEvents:
                      location.pathname === '/attack-surface/web-applications'
                        ? 'none'
                        : 'all',
                    borderLeft:
                      location.pathname === '/attack-surface/web-applications'
                        ? '4px solid var(--color-primary)'
                        : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname === '/attack-surface/web-applications'}>
                  Web Applications
                  <Link to="/attack-surface/web-applications" />
                </MenuItem>
                {/* <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? 'none'
                      : 'all',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/possible-asset-exposure'
                  )}>
                  Third Party Assets
                  <Link to="/attack-surface/possible-asset-exposure" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/technologies-list'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? 'none'
                      : 'all',
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/technologies'
                  )}>
                  Technologies
                  <Link to="/attack-surface/technologies" />
                </MenuItem> */}
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/asset-screenshots'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/attack-surface/asset-screenshots'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/asset-screenshots'
                    )
                      ? 'none'
                      : 'all',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/asset-screenshots'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/asset-screenshots'
                  )}>
                  Screenshots
                  <Link to="/attack-surface/asset-screenshots" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/customer-mindmap'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/attack-surface/customer-mindmap'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/customer-mindmap'
                    )
                      ? 'none'
                      : 'all',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/customer-mindmap'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/customer-mindmap'
                  )}>
                  Attack Surface Graph
                  <Link to="/attack-surface/customer-mindmap" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                title={
                  <p
                    style={{
                      color: 'var(--color-white)',
                      fontSize: '14px'
                    }}>
                    External Supply Chain
                  </p>
                }
                id="supply_chain"
                onClick={(e) => handleSubmenuClick(e, 'supply_chain')}
                open={selectedMenu.supply_chain}
                className="Expanded"
                icon={<img src={SupplyChainWhite} alt="Supply Chain icon" width={20} />}>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? 'none'
                      : 'all',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/possible-asset-exposure'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/possible-asset-exposure'
                  )}>
                  Third Party Assets
                  <Link to="/attack-surface/possible-asset-exposure" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/technologies'
                    )
                      ? 'none'
                      : 'all',
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/technologies'
                  )}>
                  Technologies With CVEs
                  <Link to="/attack-surface/technologies" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/attack-surface/technology'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/attack-surface/technology'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    borderLeft: location.pathname.includes(
                      '/attack-surface/technology'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)',
                    pointerEvents: location.pathname.includes(
                      '/attack-surface/technology'
                    )
                      ? 'none'
                      : 'all',
                  }}
                  active={location.pathname.includes(
                    '/attack-surface/technology'
                  )}>
                  All Technologies
                  <Link to="/attack-surface/technology" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                title={
                  <p
                    style={{
                      color: 'var(--color-white)',
                      fontSize: '14px'
                    }}>
                    Data Leaks
                  </p>
                }
                onClick={(e) => handleSubmenuClick(e, 'dataleak')}
                open={selectedMenu.data_leaks}
                className="Expanded"
                icon={<img src={DataleakWhite} alt="attack surface icon" />}>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color:
                      location.pathname === '/code-exposures'
                        ? 'var(--color-text)'
                        : 'var(--color-white)',
                    background:
                      location.pathname === '/code-exposures'
                        ? 'var(--color-white)'
                        : 'none',
                    pointerEvents:
                      location.pathname === '/code-exposures' ? 'none' : 'all',
                    borderLeft:
                      location.pathname === '/code-exposures'
                        ? '4px solid var(--color-primary)'
                        : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname === '/code-exposures'}>
                  All Data Leaks
                  <Link to="/code-exposures?is_obsolete=false" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/code-exposures/table-layout'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/code-exposures/table-layout'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    pointerEvents: location.pathname.includes(
                      '/code-exposures/table-layout'
                    )
                      ? 'none'
                      : 'all',
                    borderLeft: location.pathname.includes(
                      '/code-exposures/table-layout'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname.includes(
                    '/code-exposures/table-layout'
                  )}>
                  Tabular View
                  <Link to="/code-exposures/table-layout" />
                </MenuItem>
              </SubMenu>
              <SubMenu
                title={
                  <p
                    style={{
                      color: 'var(--color-white)',
                      fontSize: '14px'
                    }}>
                    Security Risks
                  </p>
                }
                onClick={(e) => handleSubmenuClick(e, 'securityRisk')}
                open={selectedMenu.security_risks}
                className="Expanded"
                icon={<img src={SecurityWhite} alt="attack surface icon" />}>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color:
                      location.pathname === '/security-risks'
                        ? 'var(--color-text)'
                        : 'var(--color-white)',
                    background:
                      location.pathname === '/security-risks'
                        ? 'var(--color-white)'
                        : 'none',
                    pointerEvents:
                      location.pathname === '/security-risks' ? 'none' : 'all',
                    borderLeft:
                      location.pathname === '/security-risks'
                        ? '4px solid var(--color-primary)'
                        : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname === '/security-risks'}>
                  All Security Risks
                  <Link to="/security-risks?is_obsolete=false" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/security-risks/table-layout'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/security-risks/table-layout'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    pointerEvents: location.pathname.includes(
                      '/security-risks/table-layout'
                    )
                      ? 'none'
                      : 'all',
                    borderLeft: location.pathname.includes(
                      '/security-risks/table-layout'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname.includes(
                    '/security-risks/table-layout'
                  )}>
                  Tabular View
                  <Link to="/security-risks/table-layout" />
                </MenuItem>
                <MenuItem
                  className={classes.subMenuItems}
                  style={{
                    color: location.pathname.includes(
                      '/security-risks/vulnerabilities'
                    )
                      ? 'var(--color-text)'
                      : 'var(--color-white)',
                    background: location.pathname.includes(
                      '/security-risks/vulnerabilities'
                    )
                      ? 'var(--color-white)'
                      : 'none',
                    pointerEvents: location.pathname.includes(
                      '/security-risks/vulnerabilities'
                    )
                      ? 'none'
                      : 'all',
                    borderLeft: location.pathname.includes(
                      '/security-risks/vulnerabilities'
                    )
                      ? '4px solid var(--color-primary)'
                      : '4px solid var(--color-blueBG)'
                  }}
                  active={location.pathname.includes(
                    '/security-risks/vulnerabilities'
                  )}>
                  Detailed View
                  <Link to="/security-risks/vulnerabilities" />
                </MenuItem>
              </SubMenu>

              <MenuItem
                active={selectedMenu.insights}
                className={classes.menuItems}
                style={{
                  pointerEvents: selectedMenu.insights ? 'none' : 'all',
                  color: selectedMenu.insights
                    ? 'black'
                    : 'var(--color-white)',
                  background: selectedMenu.insights
                    ? 'var(--color-white)'
                    : 'var(--color-blueBG)',
                  borderLeft: selectedMenu.insights
                    ? '2px solid var(--color-primary)'
                    : 'none'
                }}
                icon={
                  selectedMenu.insights ? (
                    <img src={InsightBlack} alt="Insight icon" width={20} />
                  ) : (
                    <img src={InsightWhite} alt="Insight icon" width={20} />
                  )
                }>
                Insights
                <Link to="/insights" />
              </MenuItem>
              <MenuItem
                active={selectedMenu.issue_tracker}
                className={classes.menuItems}
                style={{
                  pointerEvents: selectedMenu.issue_tracker ? 'none' : 'all',
                  color: selectedMenu.issue_tracker
                    ? 'black'
                    : 'var(--color-white)',
                  background: selectedMenu.issue_tracker
                    ? 'var(--color-white)'
                    : 'var(--color-blueBG)',
                  borderLeft: selectedMenu.issue_tracker
                    ? '2px solid var(--color-primary)'
                    : 'none'
                }}
                icon={
                  selectedMenu.issue_tracker ? (
                    <img src={IssueBlack} alt="Issue tracker icon" />
                  ) : (
                    <img src={IssueWhite} alt="issue tracker icon" />
                  )
                }>
                Issue Tracker
                <Link to="/issue-tracker/kanban" />
              </MenuItem>
              {reportToLevel === 'ENABLED' ?
                <MenuItem
                  active={selectedMenu.report}
                  className={classes.menuItems}
                  style={{
                    pointerEvents: reportToLevel === 'ENABLED' ? selectedMenu.report ? 'none' : 'all' : 'none',
                    color: reportToLevel === 'DISABLED' ? 'var(--color-delete)' : selectedMenu.report
                      ? 'black'
                      : 'var(--color-white)',
                    background: selectedMenu.report
                      ? 'var(--color-white)'
                      : 'var(--color-blueBG)',
                    borderLeft: selectedMenu.report
                      ? '2px solid var(--color-primary)'
                      : 'none'
                  }}
                  icon={
                    selectedMenu.report ? (
                      <img src={report} alt="Report icon" width={20} />
                    ) : (
                      <img src={reportWhite} alt="Report icon" width={20} style={{ filter: reportToLevel === 'DISABLED' ? 'brightness(0.5)' : 'unset' }} />
                    )
                  }>
                  Reports
                  <Link to="/historical-downloads">&nbsp;</Link>
                </MenuItem>
                :
                <CustomTooltip title={"You do not have required permission to access this"}>
                  <MenuItem
                    active={selectedMenu.report}
                    className={classes.menuItems}
                    style={{
                      pointerEvents: selectedMenu.report ? 'none' : 'all',
                      color: reportToLevel === 'DISABLED' ? 'var(--color-delete)' : selectedMenu.report
                        ? 'black'
                        : 'var(--color-white)',
                      background: selectedMenu.report
                        ? 'var(--color-white)'
                        : 'var(--color-blueBG)',
                      borderLeft: selectedMenu.report
                        ? '2px solid var(--color-primary)'
                        : 'none'
                    }}
                    icon={
                      selectedMenu.report ? (
                        <img src={report} alt="Report icon" width={20} />
                      ) : (
                        <img src={reportWhite} alt="Report icon" width={20} style={{ filter: reportToLevel === 'DISABLED' ? 'brightness(0.5)' : 'unset' }} />
                      )
                    }>
                    Reports
                  </MenuItem>
                </CustomTooltip>
              }
              {role === 'MANAGER' || role === 'ADMIN' || role === "PENDING" || role === "ORG_PENDING" ?
                <SubMenu
                  title={
                    <p
                      style={{
                        color: 'var(--color-white)',
                        fontSize: '14px'
                      }}>
                      Settings
                    </p>
                  }
                  open={selectedMenu.settings}
                  onClick={(e) => handleSubmenuClick(e, 'settings')}
                  className="Expanded"
                  icon={<img src={SettingsWhite} alt="attack surface icon" />}>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings'
                          ? 'var(--color-text)'
                          : 'var(--color-white)',
                      background:
                        location.pathname === '/settings'
                          ? 'var(--color-white)'
                          : 'none',
                      pointerEvents:
                        location.pathname === '/settings' ? 'none' : 'all',
                      borderLeft:
                        location.pathname === '/settings'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-blueBG)'
                    }}
                    active={location.pathname === '/settings'}>
                    Seed Information
                    <Link to="/settings" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/whitelisted-ips'
                          ? 'var(--color-text)'
                          : 'var(--color-white)',
                      background:
                        location.pathname === '/settings/whitelisted-ips'
                          ? 'var(--color-white)'
                          : 'none',
                      pointerEvents:
                        location.pathname === '/settings/whitelisted-ips' ? 'none' : 'all',
                      borderLeft:
                        location.pathname === '/settings/whitelisted-ips'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-blueBG)'
                    }}
                    active={location.pathname === '/settings/whitelisted-ips'}>
                    Scanner IP Addresses
                    <Link to="/settings/whitelisted-ips" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/scan-summary'
                          ? 'var(--color-text)'
                          : 'var(--color-white)',
                      background:
                        location.pathname === '/settings/scan-summary'
                          ? 'var(--color-white)'
                          : 'none',
                      pointerEvents:
                        location.pathname === '/settings/scan-summary' ? 'none' : 'all',
                      borderLeft:
                        location.pathname === '/settings/scan-summary'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-blueBG)'
                    }}
                    active={location.pathname === '/settings/scan-summary'}>
                    Scan Summary
                    <Link to="/settings/scan-summary" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/muted-instances'
                          ? 'var(--color-text)'
                          : 'var(--color-white)',
                      background:
                        location.pathname === '/settings/muted-instances'
                          ? 'var(--color-white)'
                          : 'none',
                      pointerEvents:
                        location.pathname === '/settings/muted-instances'
                          ? 'none'
                          : 'all',
                      borderLeft:
                        location.pathname === '/settings/muted-instances'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-blueBG)'
                    }}
                    active={location.pathname === '/settings/muted-instances'}>
                    Muted Instances
                    <Link to="/settings/muted-instances" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/integrations'
                          ? 'var(--color-text)'
                          : 'var(--color-white)',
                      background:
                        location.pathname === '/settings/integrations'
                          ? 'var(--color-white)'
                          : 'none',
                      pointerEvents:
                        location.pathname === '/settings/integrations'
                          ? 'none'
                          : 'all',
                      borderLeft:
                        location.pathname === '/settings/integrations'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-blueBG)'
                    }}
                    active={location.pathname === '/settings/integrations'}>
                    Integrations and API
                    <Link to="/settings/integrations" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/user-profile'
                          ? 'var(--color-text)'
                          : 'var(--color-white)',
                      background:
                        location.pathname === '/settings/user-profile'
                          ? 'var(--color-white)'
                          : 'none',
                      pointerEvents:
                        location.pathname === '/settings/user-profile'
                          ? 'none'
                          : 'all',
                      borderLeft:
                        location.pathname === '/settings/user-profile'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-blueBG)'
                    }}
                    active={location.pathname === '/settings/user-profile'}>
                    Profile Page
                    <Link to="/settings/user-profile" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/org-team'
                          ? 'var(--color-text)'
                          : 'var(--color-white)',
                      background:
                        location.pathname === '/settings/org-team'
                          ? 'var(--color-white)'
                          : 'none',
                      pointerEvents:
                        location.pathname === '/settings/org-team'
                          ? 'none'
                          : 'all',
                      borderLeft:
                        location.pathname === '/settings/org-team'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-blueBG)'
                    }}
                    active={location.pathname === '/settings/org-team'}>
                    Team & Org
                    <Link to="/settings/org-team" />
                  </MenuItem>
                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/asset-grouping'
                          ? 'var(--color-text)'
                          : 'var(--color-white)',
                      background:
                        location.pathname === '/settings/asset-grouping'
                          ? 'var(--color-white)'
                          : 'none',
                      pointerEvents:
                        location.pathname === '/settings/asset-grouping'
                          ? 'none'
                          : 'all',
                      borderLeft:
                        location.pathname === '/settings/asset-grouping'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-blueBG)'
                    }}
                    active={location.pathname === '/settings/asset-grouping'}>
                    Asset Grouping
                    <Link to="/settings/asset-grouping" />
                  </MenuItem>

                  <MenuItem
                    className={classes.subMenuItems}
                    style={{
                      color:
                        location.pathname === '/settings/preferences'
                          ? 'var(--color-text)'
                          : 'var(--color-white)',
                      background:
                        location.pathname === '/settings/preferences'
                          ? 'var(--color-white)'
                          : 'none',
                      pointerEvents:
                        location.pathname === '/settings/preferences'
                          ? 'none'
                          : 'all',
                      borderLeft:
                        location.pathname === '/settings/preferences'
                          ? '4px solid var(--color-primary)'
                          : '4px solid var(--color-blueBG)'
                    }}
                    active={location.pathname === '/settings/preferences'}>
                    Notifications Preferences
                    <Link to="/settings/preferences" />
                  </MenuItem>

                  {role === 'MANAGER' ? null : (
                    <MenuItem
                      className={classes.subMenuItems}
                      style={{
                        color:
                          location.pathname === '/settings/billing'
                            ? 'var(--color-text)'
                            : 'var(--color-white)',
                        background:
                          location.pathname === '/settings/billing'
                            ? 'var(--color-white)'
                            : 'none',
                        pointerEvents:
                          location.pathname === '/settings/billing'
                            ? 'none'
                            : 'all',
                        borderLeft:
                          location.pathname === '/settings/billing'
                            ? '4px solid var(--color-primary)'
                            : '4px solid var(--color-blueBG)'
                      }}
                      active={location.pathname === '/settings/billing'}>
                      Billing and Plans
                      <Link to="/settings/billing" />
                    </MenuItem>
                  )}
                </SubMenu>
                : null
              }
            </Menu>
          )
          }
        </SidebarContent >
        <SidebarFooter
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '50px',
            justifyContent: 'center',
            background: 'var(--color-blueBG)',
            paddingBottom: warningVisible ? 72 : 65
          }}>
          {sidebarCollapsed ? (
            <CustomTooltip title="Expand Sidebar">
              <img
                onClick={(e) => handleCollapse(e, 'expand')}
                style={{ cursor: 'pointer', padding: 15 }}
                src={expandDark}
                alt="expand"
              />
            </CustomTooltip>
          ) : (
            <div
              onClick={(e) => handleCollapse(e, 'collapse')}
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                cursor: 'pointer',
                justifyContent: 'center'
              }}>
              <img src={collapseDark} alt="collapse" />
              <p
                style={{
                  color: 'var(--color-white)',
                  marginTop: darkTheme ? 3 : 'none',
                  marginLeft: 15,
                  fontWeight: 500
                }}>
                Collapse Sidebar
              </p>
            </div>
          )}
        </SidebarFooter>
      </ProSidebar >
    </div >
  );
};

export default withRouter(SideBar);
