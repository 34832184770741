import {
  SAVE_ORGANISATION_DETAILS_SUCCESS,
  SAVE_ORGANISATION_DETAILS_REQUEST,
  GET_ORGANISATION_DETAILS_REQUEST
} from 'action_types/onboarding';
import { API, APIv2, fileAPI, API_Payment } from 'utils/api';
import qs from 'qs';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { errorHandler, validationErrorHandler } from 'utils/error_handler';
import {
  showSuccessModal,
  showAddJiraTypeModal,
  hideAddJiraTypeModal,
  hideAddUserModal,
  hideEditOrgModal,
  hideJiraStatusModal,
  hideJiraDetailsModal,
  hideServiceNowStatusModal,
  hideServiceNowConnectionModal,
  hidePriotityMappingModal
} from 'actions/modals';
import countBy from 'lodash/countBy';
import forEach from 'lodash/forEach';
import startsWith from 'lodash/startsWith';
import { fetchScanDetails } from 'actions/scan_details';
import { fetchTargets } from 'actions/client_settings';
import { getCookie } from 'utils/helpers';
import updateUrlAndFetchData from "utils/update_data_url";

export const saveOrganisation = (org, history) => async (dispatch) => {
  try {
    dispatch({ type: SAVE_ORGANISATION_DETAILS_REQUEST });
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    const { data } = await fileAPI.post(
      '/user/orgs/',
      qs.stringify(org),
      config
    );
    const orgDetails = {
      organisationName: data.name,
      billingAddress: data.billing_address,
      industry: data.industry,
      website: data.website,
      status: data.status,
      users: data.users || []
    };
    dispatch({
      type: SAVE_ORGANISATION_DETAILS_SUCCESS,
      orgDetails
    });

    localStorage.setItem('role', 'VERIFIED');
    history.push({
      pathname: '/user/login-form'
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

const safeParse = data => {
  try {
    const parsedData = JSON.parse(data);
    return parsedData
  } catch (error) {
    return data;
  }
}

export const saveOrgDetails = (orgDetails, history) => async (dispatch) => {
  try {
    dispatch({ type: 'SAVE_ORG_DETAILS_REQUEST' });
    let urlParams = getCookie('URLParams');
    if (urlParams) {
      urlParams = safeParse(urlParams);
    }
    if (urlParams?.['x-amzn-marketplace-token']) {
      orgDetails['x-amzn-marketplace-token'] = urlParams?.['x-amzn-marketplace-token'];
    }
    const { data } = await API.post('/user/attach-org/', orgDetails);
    const orgInfo = {
      organisationName: data.name,
      billingAddress: data.billing_address,
      industry: data.industry,
      website: data.website,
      status: data.status,
      users: data.users || []
    };
    dispatch({
      type: 'SAVE_ORG_DETAILS_SUCCESS',
      orgInfo
    });
    dispatch({
      type: 'UPDATE_ROLE',
      role:data.role
    });

    localStorage.setItem('role', data.role);
    data.role === 'ADMIN'
      ? history.push({
        pathname: '/user/login-form'
      })
      : history.push({
        pathname: '/user/admin-confirmation'
      });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getIndustryDetails = (history) => async (dispatch) => {
  try {
    const { data } = await API.get('/orgs/industry-list/');

    dispatch({
      type: 'FETCH_INDUSTRY_LIST',
      data: data.industry_list
    });

  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchOrganisationDetails = (history) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORGANISATION_DETAILS_REQUEST });

    const { data } = await API.get('/user/orgs/');
    const organisation = data;
    const users = map(organisation.users.existing_users, (user) => ({
      id: user.id,
      fullName: user.full_name,
      email: user.email,
      role: user.role
    }));
    const pendingUsers = map(organisation.users.pending_users, (user) => ({
      id: user.id,
      fullName: user.full_name,
      email: user.email,
      role: user.role
    }));
    const inviteUsers = map(organisation.users.invited_users, (user) => ({
      id: user.id,
      fullName: user.full_name,
      email: user.email,
      role: user.role
    }));
    const orgDetails = {
      id: organisation.id,
      organisationName: organisation.name,
      org_code: organisation.org_code || '',
      billingAddress: organisation.billing_address,
      industry: organisation.industry,
      website: organisation.website,
      status: organisation.status,
      asset_groups: organisation.users.asset_groups || [],
      pendingUsers,
      inviteUsers,
      users

    };

    dispatch({
      type: SAVE_ORGANISATION_DETAILS_SUCCESS,
      orgDetails
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleJiraIntegration = (history, data) => async (dispatch) => {
  try {
    dispatch({ type: 'INTEGRATE_JIRA_REQUEST' });

    const res = await API.put(`/orgs/jira/configure/`, data);
    if (res.status === 200) {
      dispatch({
        type: 'INTEGRATE_JIRA_SUCCESS',
        data: res
      });
      dispatch(fetchTargets());
      dispatch(fetchJiraTypeList());
      dispatch(hideJiraDetailsModal());

      // dispatch({
      //   type: 'SUCCESS_MESSAGE_MODAL',
      //   message: {
      //     title: 'Success!',
      //     message: res.data.message,
      //     redirectTo: '',
      //     buttonTitle: 'Ok',
      //     showCancelButton: true,
      //     showJiraStatus: true
      //   }
      // });
      // dispatch(showAddJiraTypeModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchJiraTypeList = (history) => async (dispatch) => {
  try {
    const res = await API.get(`/orgs/jira/issue-types/`);
    if (res.status === 200) {
      dispatch({
        type: 'FETCH_JIRA_TYPE_SUCCESS',
        data: res.data
      });
      dispatch(showAddJiraTypeModal());
    }
  } catch (error) {
    dispatch(hideAddJiraTypeModal());
    errorHandler(error, dispatch, history);
  }
};
export const addJiraType = (params) => async (dispatch) => {
  try {
    // dispatch({ type: 'INTEGRATE_JIRA_REQUEST' });

    const res = await API.put(`/orgs/jira/issue-types/`, params);
    if (res.status === 200) {
      dispatch(fetchTargets());
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: res.data.message,
          redirectTo: '',
          buttonTitle: 'Ok',
          showCancelButton: true,
          showJiraStatus: true
        }
      });
      dispatch(showSuccessModal());
    }
  } catch (error) {
    errorHandler(error, dispatch);
  }
};
export const deleteJiraIntegration = (history) => async (dispatch) => {
  try {
    const response = await API.delete(`/orgs/remove-jira/`);
    if (response.status === 204 || response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Deleted successfully',
          redirectTo: '',
          buttonTitle: 'Ok',
          showCancelButton: false,
          showJiraStatus: false
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchTargets());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const deleteSlackIntegration = (history) => async (dispatch) => {
  try {
    const response = await API.delete(`/orgs/remove-slack/`);
    if (response.status === 204 || response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Deleted successfully',
          redirectTo: '',
          buttonTitle: 'Ok',
          showCancelButton: false,
          showJiraStatus: false
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchTargets());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getServiceNowStatus = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(`orgs/servicenow/statuses/`);
    dispatch({
      type: 'GET_SERVICE_NOW_STATUS',
      data: data
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getServiceNowMappingStatus = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS_REQUEST' });
    const { data } = await API.get(`/orgs/servicenow/mapping/`);
    dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS' });
    dispatch({
      type: 'GET_SERVICE_NOW_MAPPING_STATUS',
      data: data
    });
  } catch (error) {
    dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS' });
    errorHandler(error, dispatch, history);
  }
};
export const getJiraStatus = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(`/orgs/jira/statuses/`);

    dispatch({
      type: 'GET_JIRA_STATUS',
      data: data.statuses
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getJiraPriority = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(`/orgs/jira/priority/`);

    dispatch({
      type: 'GET_JIRA_PRIORITY',
      data: data
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const updateJiraPriority = (statuses, history) => async (dispatch) => {
  try {
    const { data } = await API.post(`/orgs/jira/priority/mapping/`, statuses);

    dispatch({
      type: 'SUCCESS_MESSAGE_MODAL',
      message: {
        title: 'Success!',
        message: 'Jira Priority Details Updated',
        redirectTo: '',
        buttonTitle: 'Ok',
      }
    });
    dispatch(showSuccessModal());
    dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS' });
    dispatch(hidePriotityMappingModal());
    dispatch(getJiraPriorityMapping());
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const getJiraPriorityMapping = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(`/orgs/jira/priority/mapping/`);
    dispatch({
      type: 'GET_JIRA_PRIORITY_MAPPING',
      data: data
    });

  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const getIssueTrackerStatus = (history) => async (dispatch) => {
  try {
    const { data } = await API.get(`/orgs/issue-tracker/statuses/`);

    dispatch({
      type: 'GET_ISSUE_TRACKER_STATUS',
      data: data.statuses
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchMappingJiraStatus = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS_REQUEST' });
    const { data } = await API.get(`/orgs/jira-status/mapping/`);
    dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS' });
    dispatch({
      type: 'FETCH_MAPPING_JIRA_STATUS',
      data
    });
  } catch (error) {
    dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS' });
    errorHandler(error, dispatch, history);
  }
};
export const fetchJiraToRhlStatus = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS_REQUEST' });
    const { data } = await API.get(`/orgs/jira-status/rev-mapping/`);
    dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS' });
    dispatch({
      type: 'FETCH_MAPPING_JIRA_TO_RHL_STATUS',
      data
    });
  } catch (error) {
    dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS' });
    errorHandler(error, dispatch, history);
  }
};

export const updateMappingServiceNowStatus = (statuses, history) => async (
  dispatch
) => {
  try {
    const response = await API.post(`/orgs/servicenow/mapping/`, statuses);
    if (response.status === 200 || response.status === 201) {
      dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS_REQUEST' });
      dispatch({
        type: 'GET_SERVICE_NOW_MAPPING_STATUS',
        data: response.data
      });
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Service Now Details Updated',
          redirectTo: '',
          buttonTitle: 'Ok',
        }
      });
      dispatch(showSuccessModal());
      dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS' });
      dispatch(hideServiceNowStatusModal());
      dispatch(hideServiceNowConnectionModal());
      dispatch(hidePriotityMappingModal());
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const updateMappingJiraStatus = (statuses, history) => async (
  dispatch
) => {
  try {
    const response = await API.post(`/orgs/jira-status/mapping/`, statuses);
    if (response.status === 200) {
      dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS_REQUEST' });
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'RHL to Jira status mapping updated successfully',
          redirectTo: '',
          buttonTitle: 'Ok',
          showJiraStatus: false
        }
      });
      dispatch(showSuccessModal());
      setTimeout(() => {
        dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS' });
        dispatch(hideJiraStatusModal());
      }, 500);
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const updateMappingJiraToRhlStatus = (statuses, history) => async (
  dispatch
) => {
  try {
    const response = await API.post(`/orgs/jira-status/rev-mapping/`, statuses);
    if (response.status === 200) {
      dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS_REQUEST' });
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Jira to RHL status mapping updated successfully',
          redirectTo: '',
          buttonTitle: 'Ok',
          showJiraStatus: false
        }
      });
      dispatch(showSuccessModal());
      dispatch(hideJiraStatusModal());
      setTimeout(() => {
        dispatch({ type: 'FETCH_JIRA_STATUS_SUCCESS' });
      }, 500);
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchUsers = (filterUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_USERS_REQUEST' });

    const baseUrl = `/users/list/`;
    const { data } = await API.get(`${baseUrl}${filterUrl}`);

    dispatch({
      type: 'GET_USERS_SUCCESS',
      users: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      usersCount: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextUsers = (history, nextUrl) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_USERS_REQUEST' });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: 'GET_USERS_SUCCESS',
      users: data.results,
      nextItems: data.next,
      previousItems: data.previous,
      usersCount: data.count
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchOrgsCountByStatus = (data) => (dispatch, getState) => {
  const state = getState();
  const cards = state.cards.rhOrganisationsPage;

  const rhOrgPageCards = map(cards, (card) => ({ ...card }));
  const rhOrgPageNumbers = countBy(data, 'status');

  const newCards = map(rhOrgPageCards, (card) => {
    let newCard = { ...card };
    forEach(rhOrgPageNumbers, (value, element) => {
      if (!startsWith(card.title, element)) {
        return;
      }
      newCard = { ...card, values: value };
    });
    return newCard;
  });
  dispatch({ type: 'RH_ORG_CARDS', payload: newCards });
};

export const fetchOrganisations = (filterUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_ORGS_REQUEST' });

    const baseUrl = `/orgs/`;
    const { data } = await APIv2.get(`${baseUrl}${filterUrl}`);

    dispatch({
      type: 'GET_ORGS_SUCCESS',
      orgs: data.results,
      nextItems: data.next ? data.next.replace("http", "https") : data.next,
      previousItems: data.previous ? data.previous.replace("http", "https") : data.previous,
      count: data.count
    });
    dispatch(fetchOrgsCountByStatus(data.results));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchCategoryCount = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_CATEGORY_COUNT_REQUEST' });

    const { data } = await APIv2.get(`subscription/`);

    if (data) {
      dispatch({
        type: 'FETCH_CATEGORY_COUNT_SUCCESS',
        data
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchSearchedOrg = (history, searchUrl) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_SEARCHED_ORG_REQUEST' });

    const baseUrl = `/orgs/`;
    const { data } = await APIv2.get(`${baseUrl}${searchUrl}`);

    if (data) {
      dispatch({
        type: 'FETCH_SEARCHED_ORG_SUCCESS',
        data: data.results
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchActiveOrgs = (activeUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_ACTIVE_ORGS_REQUEST' });

    const baseUrl = `/orgs/`;
    const { data } = await APIv2.get(`${activeUrl}`);

    dispatch({
      type: 'FETCH_ACTIVE_ORGS_SUCCESS',
      orgs: data.results,
      nextItems: data.next ? data.next.replace("http", "https") : data.next,
      nextItems: data.previous ? data.previous.replace("http", "https") : data.previous,
      count: data.count
    });
    dispatch(fetchOrgsCountByStatus(data.results));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchNextOrgs = (history, nextUrl) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_ORGS_REQUEST' });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: 'FETCH_ORGS_SUCCESS',
      orgs: data.results,
      count: data.count,
      nextItems: data.next,
      previousItems: data.previous,
      currentUrl: nextUrl,
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchOrgsList = (activeUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_ORGS_REQUEST' });

    const baseUrl = `/orgs/`;
    const { data } = await APIv2.get(`${baseUrl}${activeUrl}`);
    dispatch({
      type: 'FETCH_ORGS_SUCCESS',
      orgs: data.results,
      count: data.count,
      nextItems: data.next,
      previousItems: data.previous,
      currentUrl: activeUrl,
    });
    dispatch(fetchOrgsCountByStatus(data.results));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const updateSubscription = (orgId, activeUrl, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SUBSCRIPTION_UPDATE_REQUEST' });

    const response = await API.put(`/org/${orgId}/upgrade/`, {
      plan:
        activeUrl === 'support'
          ? 'CANCELLED'
          : activeUrl === 'freeOrgs'
            ? 'RH-ADMIN'
            : 'RH-ADMIN'
    });
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message:
            activeUrl === 'support'
              ? `Subscription deleted successfully`
              : activeUrl === 'freeOrgs' || activeUrl === 'inactiveOrgs'
                ? `Subscription added successfully`
                : activeUrl === 'activeOrgs'
                  ? `Subscription updated successfully`
                  : '',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchCategoryCount(history));
      dispatch(
        fetchOrgsList(
          `?subscription=${activeUrl === 'freeOrgs'
            ? 'free'
            : activeUrl === 'activeOrgs'
              ? 'active'
              : activeUrl
          }`,
          history
        )
      );
      dispatch({
        type: 'FETCH_SUBSCRIPTION_UPDATE_SUCCESS'
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const enableSchedule = (orgId, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SUBSCRIPTION_UPDATE_REQUEST' });

    const { data } = await API.post(`/org/enable-schedule/`, {
      org_id: orgId
    });
    dispatch({
      type: 'SUCCESS_MESSAGE_MODAL',
      message: {
        title: 'Success!',
        message: 'Enable Schedule successfully.',
        redirectTo: '',
        buttonTitle: 'Ok'
      }
    });
    dispatch(showSuccessModal());
    const baseUrl = "/admin/orgs";
    await updateUrlAndFetchData(
      baseUrl,
      dispatch,
      fetchOrgsList,
      history
    );
    dispatch({
      type: 'FETCH_SUBSCRIPTION_UPDATE_SUCCESS'
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const addEnterprice = (orgId, activeUrl, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SUBSCRIPTION_UPDATE_REQUEST' });

    const response = await API_Payment.post(`/convert-to-enterprise/`, {
      org_id: orgId
    });
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Enterprise added successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      // dispatch(fetchCategoryCount(history));
      const baseUrl = "/admin/orgs";
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchOrgsList,
       history
      );
      dispatch({
        type: 'FETCH_SUBSCRIPTION_UPDATE_SUCCESS'
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const cancelEnterprice = (orgId, activeUrl, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_SUBSCRIPTION_UPDATE_REQUEST' });

    const response = await API_Payment.post(`/cancel-enterprise/`, {
      org_id: orgId
    });
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Enterprise canceled successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      // dispatch(fetchCategoryCount(history));
      // dispatch(
      //   fetchOrgsList(
      //     `?subscription=${activeUrl === 'freeOrgs'
      //       ? 'free'
      //       : activeUrl === 'activeOrgs'
      //         ? 'active'
      //         : activeUrl
      //     }`,
      //     history
      //   )
      // );
      const baseUrl = "/admin/orgs";
      await updateUrlAndFetchData(
        baseUrl,
        dispatch,
        fetchOrgsList,
        history
      );
      dispatch({
        type: 'FETCH_SUBSCRIPTION_UPDATE_SUCCESS'
      });
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchReqDomain = (history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_REQ_DOMAIN_REQUEST' });

    const { data } = await APIv2.get(`/orgs/req_domain/`);

    dispatch({
      type: 'FETCH_REQ_DOMAIN_SUCCESS',
      data: data
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const fetchReqDomainNext = (history, nextUrl) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_REQ_DOMAIN_REQUEST' });

    const { data } = await APIv2.get(nextUrl);

    dispatch({
      type: 'FETCH_REQ_DOMAIN_SUCCESS',
      data: data,
      count: 6,
      nextItems: null,
      previousItems: null
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchInactiveOrgs = (inactiveUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_INACTIVE_ORGS_REQUEST' });

    const baseUrl = `/orgs/`;
    const { data } = await APIv2.get(`$${inactiveUrl}`);

    dispatch({
      type: 'FETCH_INACTIVE__ORGS_SUCCESS',
      orgs: data.results,
      nextItems: data.next ? data.next.replace('http', 'https') : data.next,
      previousItems: data.previous ? data.previous.replace('http', 'https') : data.previous,
      count: data.count
    });
    dispatch(fetchOrgsCountByStatus(data.results));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchFreeOrgs = (freeUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_FREE_ORGS_REQUEST' });

    const baseUrl = `/orgs/`;
    const { data } = await APIv2.get(`${freeUrl.includes('https') ? freeUrl : freeUrl.replace("http", "https")}`);


    dispatch({
      type: 'FETCH_FREE_ORGS_SUCCESS',
      orgs: data.results,
      nextItems: data.next ? data.next.replace('http', 'https') : data.next,
      previousItems: data.previous ? data.previous.replace('http', 'https') : data.previous,
      count: data.count
    });
    dispatch(fetchOrgsCountByStatus(data.results));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchSupportOrgs = (supportUrl, history) => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_SUPPORT_ORGS_REQUEST' });

    const baseUrl = `/orgs/`;
    const { data } = await APIv2.get(`${supportUrl}`);

    dispatch({
      type: 'FETCH_SUPPORT_ORGS_SUCCESS',
      orgs: data.results,
      nextItems: data.next ? data.next.replace('http', 'https') : data.next,
      previousItems: data.previous ? data.previous.replace('http', 'https') : data.previous,
      count: data.count
    });
    dispatch(fetchOrgsCountByStatus(data.results));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchNextOrganisations = (history, nextUrl) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_ORGS_REQUEST' });

    const { data } = await API.get(nextUrl);

    dispatch({
      type: 'GET_ORGS_SUCCESS',
      orgs: data.results,
      nextItems: data.next ? data.next.replace('http', 'https') : data.next,
      previousItems: data.previous ? data.previous.replace('http', 'https') : data.previous,
      usersCount: data.count
    });
    dispatch(fetchOrgsCountByStatus(data.results));
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
export const addUser = (user, history) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_ORGS_REQUEST' });

    const requestObject = {
      full_name: user.fullName,
      email: user.email,
      role: user.role
    };

    const response = await API.post(`/users`, requestObject);
    if (response.status === 201) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'User invite has been sent successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(hideAddUserModal());
      dispatch(showSuccessModal());
      dispatch(fetchOrganisationDetails());
    }
  } catch (error) {
    validationErrorHandler(error, dispatch, history);
  }
};

export const inviteUser = (user, history) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_ORGS_REQUEST' });

    const requestObject = {
      full_name: user.fullName,
      email: user.email,
      role: user.role,
      asset_group_ids: user.asset_group_ids
    };

    const response = await API.post(`/invite-user/`, requestObject);
    if (response.status === 201) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: response.data.message,
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(hideAddUserModal());
      dispatch(showSuccessModal());
      dispatch(fetchOrganisationDetails());
    }
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(hideAddUserModal());
      errorHandler(error, dispatch, history);
    } else {
      validationErrorHandler(error, dispatch, history);
    }
  }
};

export const verifyUser = (userId, history) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_ORGS_REQUEST' });

    const requestObject = {
      user_id: userId
    };
    const response = await API.put(`/verify/user/`, requestObject);

    if (response.status === 201) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'The user is verified successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchUsers('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchUserRoles = () => async (dispatch, getState) => {
  const role = getState().authentication.role;
  if (!role.includes('ADMIN')) {
    dispatch({
      type: 'FETCH_ALL_USER_ROLES',
      roles: []
    });
    return;
  }
  try {
    const { data } = await API.get(`/roles/`);
    const clientRoles = filter(data.roles, (role) => !startsWith(role, 'rh'));

    dispatch({
      type: 'FETCH_ALL_USER_ROLES',
      roles: clientRoles
    });
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const updateUserRole = (user, history) => async (dispatch) => {
  try {
    const response = await API.put(`/users/`, user);
    if (response.status === 200) {
      dispatch(fetchOrganisationDetails(history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const deleteUser = (user, history) => async (dispatch) => {
  try {
    const response = await API.delete(`/users/`, { data: { user_id: user } });
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'User has been deleted successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchOrganisationDetails(history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const deleteInviteUser = (user, history) => async (dispatch) => {
  try {
    const response = await API.delete(`/invite-user/`, {
      data: {
        id: user
      }
    });
    if (response.status === 204 || response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Invitation deleted successfully',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchOrganisationDetails(history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleOrgSlackNotification = (request, history) => async (
  dispatch
) => {
  try {
    dispatch({
      type: 'SLACK_NOTIFICATION_REQUEST'
    });

    const response = await APIv2.put(`/slack/send-details/`, request);
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'The request was submitted successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(fetchTargets());
      dispatch(showSuccessModal());
      dispatch(fetchOrganisationDetails(history));
    }
  } catch (error) {
    validationErrorHandler(error, dispatch);
  }
};

export const handleOrgSlackTestNotification = (history) => async (dispatch) => {
  try {
    dispatch({
      type: 'SLACK_NOTIFICATION_REQUEST'
    });

    const response = await APIv2.post(`/slack/test/`, {});
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'The test notification to your slack is sent successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchOrganisationDetails(history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDisableOrganisation = (orgId, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'UPDATE_RH_ORGANISATION_LIST_REQUEST' });

    const response = await APIv2.put(`/orgs/disable/`, { org_id: orgId });
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'The organisation is disabled successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchOrganisations('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleDeleteOrganisation = (orgId, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'UPDATE_RH_ORGANISATION_LIST_REQUEST' });

    const response = await APIv2.post(`/orgs/delete/`, { org_id: orgId });
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'The organisation is deleted successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchOrganisations('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchOrgStatusList = (history) => async (dispatch) => {
  try {
    const { data } = await APIv2.get('/orgs/status-list/');
    dispatch({
      type: 'FETCH_ORGS_STATUS_LIST',
      payload: data.org_status_list
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const reverseCredits = (scanId, history) => async (dispatch) => {
  try {
    dispatch({ type: 'REVERSE_CREDITS_REQUEST' });

    const response = await API.post(`/credits/reverse/`, { scan_id: scanId });
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message:
            "Credits has been reversed back to the organisation's account.",
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchScanDetails(scanId, history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const fetchRhOrganisationDetails = (orgId, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: 'FETCH_RH_ORGANISATION_DETAILS_REQUEST' });

    const { data } = await APIv2.get(`/orgs/${orgId}/`);

    const users = map(data.users, (user) => ({
      id: user.id,
      fullName: user.full_name,
      email: user.email,
      role: user.role
    }));
    const orgDetails = {
      orgId: data.id,
      organisationName: data.name,
      billingAddress: data.billing_address,
      industry: data.industry,
      website: data.website,
      status: data.status,
      users,
      slackChannel: data.slack_channel,
      webhookUrl: data.webhook_url
    };

    dispatch({
      type: 'FETCH_RH_ORGANISATION_DETAILS_SUCCCESS',
      orgDetails
    });
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const createNewUser = (user, history) => async (dispatch) => {
  try {
    dispatch({ type: 'UPDATE_RH_USERS_LIST_REQUEST' });

    const requestObject = {
      full_name: user.fullName,
      email: user.email,
      role: user.role,
      org_id: user.orgId
    };
    const response = await API.post('/users/create/', requestObject);
    if (response.status === 201) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'The user is added successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(hideAddUserModal());
      dispatch(showSuccessModal());
      dispatch(fetchOrganisations('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const handleEditOrg = (org, history) => async (dispatch) => {
  try {
    dispatch({ type: 'EDIT_ORGANISATION_DETAILS_REQUEST' });
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    const response = await fileAPI.put(
      '/user/orgs/',
      qs.stringify(org),
      config
    );
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'New details has been saved successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(hideEditOrgModal());
      dispatch(fetchOrganisationDetails(history));
    }
  } catch (error) {
    validationErrorHandler(error, dispatch, history);
  }
};

export const addSubscription = (row, history) => async (dispatch) => {
  try {
    const requestObject = {
      plan: 'RH-ADMIN'
    };
    const response = await API.put(`/org/${row.id}/upgrade/`, requestObject);
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Subscription has been successfully added.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchOrganisations('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};

export const deleteSubscription = (row, history) => async (dispatch) => {
  try {
    const requestObject = {
      plan: 'CANCELLED'
    };
    const response = await API.put(`/org/${row.id}/upgrade/`, requestObject);
    if (response.status === 200) {
      dispatch({
        type: 'SUCCESS_MESSAGE_MODAL',
        message: {
          title: 'Success!',
          message: 'Subscription is deleted successfully.',
          redirectTo: '',
          buttonTitle: 'Ok'
        }
      });
      dispatch(showSuccessModal());
      dispatch(fetchOrganisations('', history));
    }
  } catch (error) {
    errorHandler(error, dispatch, history);
  }
};
